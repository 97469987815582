<template>
  <div>
    <div class="mb-8">
      <StudentSearch
        :course="course"
        :list-loading="listLoading"
        :query="query"
        @input-change="handleInputChange"
      />
    </div>
    <v-data-table
      :custom-sort="() => studentsDataList.data.users"
      :headers="headers"
      hide-default-footer
      item-key="uuid"
      :items="studentsDataList.data.users"
      :loading="listLoading"
      multi-sort
      :sort-by="sort.fields"
      :sort-desc="sort.desc"
      @update:options="handleSort"
    >
      <template v-slot:[`header.action`]>
        <span class="subtitle-2 lightCarbon--text">
          {{ totalEntries }}
        </span>
      </template>

      <template v-slot:[`item.user`]="{ item }">
        <UserNameAndAvatar
          :full-name="`${item.user.first_name} ${item.user.last_name}`"
          :url="item.user.photo"
        />
      </template>
      <template v-slot:[`item.email`]="{ item }">
        {{ item.user.email }}
      </template>
      <template v-slot:[`item.class_missed`]="{ item }">
        {{ item.class_missed }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          class="pr-0 pr-md-2"
          color="primary"
          small
          text
          :to="{
            name: 'ViewStudentList',
            params: { batchId: batchId, studentId: item.user.uuid },
          }"
        >
          VIEW STUDENT
        </v-btn>
      </template>

      <template v-slot:footer>
        <Paginator
          :page="page"
          :total="studentsDataList.total"
          @change="handlePagination"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import StudentSearch from "@/components/dashboard/classes/StudentListSearch.vue"
import UserNameAndAvatar from "@/components/shared/UserNameAndAvatar.vue"
import urls from "@/utils/urls"
import Paginator from "@/components/shared/Paginator.vue"
import { getValueOrNull, getSortableFieldsArray } from "@/utils/helpers"

export default {
  name: "StudentList",

  components: { StudentSearch, Paginator, UserNameAndAvatar },

  data: () => ({
    batchId: null,

    value: "student",
    page: 1,
    sort: { fields: [], desc: [] },
    listLoading: false,
    query: "",
    course: "",
    batchData: {
      batch: {
        name: "Batch",
      },
    },
    headers: [
      {
        text: "Name",
        value: "user",
      },
      { text: "Email address", value: "email" },
      { text: "Missed", value: "class_missed" },
      { text: "", value: "action", sortable: false, align: "end" },
    ],
    studentsDataList: {
      data: {
        users: [],
      },
      total: 0,
    },
  }),

  computed: {
    numberOfPages() {
      return Math.ceil(this.studentsDataList.total / 10)
    },

    totalEntries() {
      return `${
        this.page * 10 > this.studentsDataList.total
          ? this.studentsDataList.total
          : this.page * 10
      } of ${this.studentsDataList.total} Entries`
    },
  },

  beforeMount: function() {
    this.batchId = this.$route.params.batchId
  },

  methods: {
    //handle Sort
    handleSort({ sortBy, sortDesc }) {
      this.resetPageNo()
      this.sort = {
        fields: sortBy,
        desc: sortDesc,
      }
      this.getStudentList()
    },

    handleInputChange(key, value) {
      this[key] = value
      key !== "page" && this.resetPageNo()
      this.getStudentList()
    },
    resetPageNo() {
      this.page = 1
    },

    //Other Api call
    async getStudentList() {
      this.listLoading = true
      try {
        const res = await this.$http.get(
          urls.classes.getStudentsList(this.batchId),
          {
            params: {
              query: getValueOrNull(this.query),
              course: getValueOrNull(this.course),
              sort: getSortableFieldsArray(this.sort.fields, this.sort.desc),
              page: this.page,
            },
          }
        )
        this.studentsDataList = res.data.data
      } catch (error) {
        console.error(error)
      } finally {
        this.listLoading = false
      }
    },
    handlePagination(newPage) {
      this.page = newPage
      this.getStudentList()
    },
  },
}
</script>

<style lang="scss" scoped>
.email {
  word-break: break-word;
}
</style>
