<template>
  <v-row :class="{ 'mt-5': $vuetify.breakpoint.mdAndDown }" dense>
    <v-col cols="12" md="8">
      <DebouncedTextField
        :input="handleInput"
        label="Search Student"
        :value="query"
      />
    </v-col>
    <v-col cols="12" md="4">
      <v-select
        class="header-fields"
        clearable
        :disabled="listLoading"
        flat
        hide-details
        item-text="name"
        item-value="uuid"
        :items="courseChoices"
        label="Course"
        solo
        :value="course"
        @input="e => $emit('input-change', 'course', e || '')"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"
import DebouncedTextField from "@/components/shared/DebouncedTextField.vue"

export default {
  name: "StudentListSearch",

  components: { DebouncedTextField },

  props: {
    query: {
      type: String,
      required: true,
    },
    course: {
      type: String,
      required: true,
    },
    listLoading: Boolean,
  },

  data: () => ({
    timeoutRef: null,
  }),

  computed: {
    ...mapGetters(["courseChoices"]),
  },

  methods: {
    handleInput(e) {
      this.$emit("input-change", "query", e)
    },
  },
}
</script>

<style lang="scss" scoped></style>
